<template>
  <div>
    <v-autocomplete
      :prepend-icon="icon ? icons.mdiAlphaCCircle : null"
      :clearable="clearable"
      hide-details="auto"
      :disabled="disabled"
      v-model="select"
      :items="lista"
      :label="label ? label : 'Bg Color'"
      item-value="value"
      item-text="nombre"
      :dense="outlined"
      :outlined="outlined"
      :loading="cargando"
      :rules="rules"
      search-input="false"
    >
      <template slot="selection" slot-scope="data">
        <v-avatar :color="data.item.color" size="20" class="mr-2">
           
        </v-avatar>
        {{ data.item.nombre }}
      </template>
      <template slot="item" slot-scope="data">
        <v-avatar  :color="data.item.color" size="20" class="mr-2">
         
        </v-avatar>
        {{ data.item.nombre }}
      </template>
    </v-autocomplete>
  </div>
</template>
<script>
import { onBeforeMount, ref, watch } from '@vue/composition-api'
import { mdiAlphaCCircle, mdiPlusCircleOutline } from '@mdi/js'
 
export default {
  props: {
    value: String,
    jsonRespueta: Boolean,
    disabled: Boolean,
    dense: Boolean,
    outlined: Boolean,
    icon: Boolean,
    todos: Boolean,
    default: Number,
    clearable: Boolean, 
    rules: Array,
    label: String,
  },
  setup(props, contex) {
    const lista = ref([
      {
        nombre: 'Naranja',
        value: 'text-warning',
        color: '#fd8d27'
      },
      {
        nombre: 'Azul',
        value: 'text-primary',
        color: '#103178'
      },
      {
        nombre: 'Blanco',
        value: 'text-white',
        color: '#ffffff'
      }, {
        nombre: 'Verde',
        value: 'text-success',
        color: '#12a05c'
      }, {
        nombre: 'Amarillo',
        value: 'text-yellow',
        color: '#ffc800'
      }, {
        nombre: 'Gris',
        value: 'text-secondary',
        color: '#6c757d'
      },
    ])
    const select = ref(null)
    const cargando = ref(false)

    watch(props, () => {
      select.value = props.value
    })
    onBeforeMount(() => {
      if (props.value != undefined) {
        select.value = props.value
      }
    })

    const selectId = id => {
      select.value = id
    }

    watch(select, () => {
      if (props.jsonRespueta == true) {
        const valor = lista.value.find(item => item.id == select.value)
        contex.emit('input', valor == undefined ? null : valor)
      } else {
        contex.emit('input', select.value)
      }
    })

    return {
      icons: {
        mdiAlphaCCircle,
        mdiPlusCircleOutline,
      },
      lista,
      select,
      cargando,
      selectId,
    }
  },
}
</script>
<template>
  <v-card v-if="datos.id > 0">
    <v-card-text>
      <v-row>
        <v-col cols="12">
          <p>{{ datos.descripcion }}</p>
          <v-carousel style="zoom: 0.7"  hide-delimiters height="600">
            <v-carousel-item v-for="(item, i) in banners" :key="i">
              <BannerExampleVue :banner="item" />
            </v-carousel-item>
          </v-carousel>
        </v-col>
        <v-col>
          <v-row justify="center">
            <v-expansion-panels accordion>
              <v-expansion-panel v-for="(item, i) in banners" :key="i">
                <v-expansion-panel-header class="my-1">{{ item.title }}</v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-carousel :show-arrows="false" style="zoom: 0.7"  hide-delimiters height="600">
                    <v-carousel-item>
                      <BannerExampleVue :banner="item" />
                    </v-carousel-item>
                  </v-carousel>

                  <v-divider class="my-6"></v-divider>
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-textarea dense rows="2" v-model="item.title" outlined label="Titulo"></v-textarea>
                      <v-textarea dense rows="2" v-model="item.desc" outlined label="Descripcion"></v-textarea>
                      <SelectTextColorVue
                        class="mb-4"
                        :dense="true"
                        :label="'Color texto'"
                        :outlined="true"
                        v-model="item.textColor"
                      />

                      <v-text-field dense label="Texto Boton" outlined v-model="item.textShop"></v-text-field>
                      <v-text-field dense label="Link Boton" outlined v-model="item.linkShop"></v-text-field>
                      <SelectBgColorVue
                        v-model="item.shop"
                        :clearable="true"
                        class="mb-4"
                        :dense="true"
                        :outlined="true"
                        :label="'Boton Color Fondo'"
                      />
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field dense label="Precio Insertar" outlined v-model="item.price.ins"></v-text-field>
                      <v-text-field dense label="Precio Tachado" outlined v-model="item.price.del"></v-text-field>

                      <v-text-field dense label="Porcentaje Valor" outlined v-model="item.percent.text"></v-text-field>
                      <SelectBgColorVue
                        v-model="item.percent.percentBgColor"
                        @change="changePercentClass(item)"
                        :clearable="true"
                        class="mb-4"
                        :dense="true"
                        :outlined="true"
                        :label="'Porcentaje Color Fondo'"
                      />
                      <v-select
                        outlined
                        dense
                        v-model="item.percent.percentPosicion"
                        @change="changePercentClass(item)"
                        :items="percentPosiciones"
                        label="Porcentaje Posicion"
                      ></v-select>
                      <v-text-field dense label="Etiqueta Superiror" outlined v-model="item.baged"></v-text-field>

                      <AddImages
                        :_width="250"
                        :_height="200"
                        :value="item.imgJson"
                        @sendImg="sendImg"
                        :_selected="true"
                        title="Imagen"
                        :_objeto="item"
                      />
                      <small class="error--text">* Es requerido</small>

                      <ColorPickerVue :dense="true" :label="'Color Fondo'" :outlined="true" v-model="item.bgColor" />
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-row>
          <v-row>
            <v-btn outlined color="primary" @click="agregar" block>Agregar una entrada</v-btn>
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-text class="text-right">
      <v-btn @click="Guardar()" class="primary mt-2">Guardar</v-btn>
    </v-card-text>
  </v-card>
</template>
  
  <script>
import { onBeforeMount, ref, watch } from '@vue/composition-api'
import BannerExampleVue from './BannerExample.vue'

import AddImages from '@/views/sistema/configuracion/imagen/componentes/AddImages.vue'
import SelectBgColorVue from './SelectBgColor.vue'
import ColorPickerVue from '../../../../../components/ColorPicker.vue'
import SelectTextColorVue from './SelectTextColor.vue'
import config from '@/api/config'
import BannerServices from '@/api/servicios/BannerServices'
import store from '@/store'

import goTo from 'vuetify/lib/services/goto'
export default {
  components: {
    BannerExampleVue,
    SelectBgColorVue,
    ColorPickerVue,
    SelectTextColorVue,
    AddImages,
  },
  setup(props,context) {
    const percentPosiciones = [
      {
        text: 'Posicion 1',
        value: 'ps-center',
      },
      {
        text: 'Posicion 2',
        value: 'ps-top',
      },
      {
        text: 'Posicion 3',
        value: 'ps-left',
      },
    ]
    const datos = ref({
      id: 0,
      descripcion: '',
      indActivo: true,
      datos: [],
    })
    const picker = ref(null)

    const changePercentClass = item => {
      item.percent.extraClass = `${item.percent.percentBgColor ?? ''} ${item.percent.percentPosicion ?? ''}`
    }
    const CargarEditar = item => {
      datos.value = { ...item, datos: JSON.parse(item.datos) }
      banners.value = JSON.parse(item.datos)
      goTo(0)
    }

    const bannerInit = Object.freeze({
      title: 'Titulo Ejemplo',
      desc: 'descripcion!',
      textColor: 'text-secondary',
      shop: 'bg-warning',
      textShop: 'comprass aqui',
      linkShop: 'http://localhost:4003/img/promotion/slide1.png',
      price: {
        del: '$00.00',
        ins: '$00.00',
      },
      percent: {
        percentBgColor: 'bg-warning',
        percentPosicion: 'ps-center',
        text: '-00%',
        extraClass: 'bg-warning ps-center',
      },
      img: 'http://localhost:4003/img/promotion/slide1.png',
      baged: 'New',
      round: 'http://localhost:4003/img/round5.png',
      bgColor: '#DAECFAFF',
      noFull: false,
    })

    const sendImg = datos => {
      console.log('datos', datos.img)
      if (datos.img.length > 0) {
        datos.objeto.img = `${config.api_imagenes}${datos.img[0].ruta}`
        datos.objeto.imgJson = datos.img
      } else {
        datos.objeto.img = 'http://localhost:4003/img/promotion/slide1.png'
        datos.objeto.imgJson = []
      }
    }
    onBeforeMount(() => {
      const myJSON = JSON.parse(JSON.stringify(bannerInit))
      banners.value = [myJSON]
    })
    const banners = ref([])
    const agregar = () => {
      const myJSON = JSON.parse(JSON.stringify(bannerInit))
      banners.value.push({ ...myJSON, title: 'Titulo Ejemplo' + (banners.value.length + 1) })
    }
    const ActualizarLista = item => { 
      context.emit('GetActualizarLista', { ...item })
    }
    const guardando = ref(false)
    const Guardar = () => {
      guardando.value = true

      try {
        BannerServices.Actualizar(store.state.user.idUsuario, { ...datos.value, datos: JSON.stringify(banners.value) })
          .then(response => {
            console.log(response)
            if (response.data.estatus == true) {
              store.commit('setAlert', {
                message: response.data.mensaje,
                type: 'success',
              })
               ActualizarLista(response.data.datos.datos)
              datos.value = {
                id: 0,
                descripcion: '',
                indActivo: true,
                datos: [],
              }
            } else {
              store.commit('setAlert', {
                message: `${response.data.mensaje}: ` + response.data.datos.mensajeError,
                type: 'warning',
              })
            }
          })
          .catch(error => {
            console.log(error)
            store.commit('setAlert', {
              message: error,
              type: 'error',
            })
          })
          .finally(() => {
            guardando.value = false
          })
      } catch (error) {
        //alert(error)
        store.commit('setAlert', {
          message: error,
          type: 'error',
        })
      }
    }
    return {
      banners,
      picker,
      agregar,
      percentPosiciones,
      sendImg,
      changePercentClass,
      CargarEditar,
      datos,
      Guardar,
      ActualizarLista
    }
  },
}
</script>

<template>
  <v-row>
    <v-col cols="12">
      <ActualizarVue ref="ActualizarRef" @GetActualizarLista="GetActualizarLista" />
    </v-col>

    <v-col cols="12">
      <Listar  ref="ListarRef" @GetEditar="GetEditar" />
    </v-col>
  </v-row>
</template>
 

  <script>
import { onBeforeMount, ref, watch } from '@vue/composition-api'

import ActualizarVue from './componentes/Actualizar.vue'
import Listar from './componentes/Listar.vue'
export default {
  components: { ActualizarVue, Listar },
  setup() {
    const ListarRef = ref(null)
    const ActualizarRef = ref(null)

    const datos = ref({
      id: -1,
      descripcion: '',
      indActivo: true,
      datos:""
    })

    const GetActualizarLista = item => {
      ListarRef.value.cargar(item)
    }
    const GetEditar = item => {
      ActualizarRef.value.CargarEditar(item)
    }

    return {
      ListarRef,
      ActualizarRef,
      datos,
      GetActualizarLista,
      GetEditar,
    }
  },
}
</script>

//import { axios } from '@/api';
import Axios from 'axios';
import store from '@/store'
import http from "..";
import router from '@/router'

class CategoriaServices {
     Consultar(
        datos = {
            Id: -1,
            Descripcion: ''
        }
    ) {
        return http.get("banner/consultar", { params: datos })
            .catch((error) => {
                if (error.response.status == 401) {
                    store.commit("setUserData", { id: 0, userName: "", isAutenticated: false });
                    router.push("/pages/login");
                }
            });
    }  
 
    
    Actualizar( idUsuario,data ) {   
        const datos = {
            IdUsuario: idUsuario,
            Data: data
          }
          console.log(datos) 
        return http.post("/banner/actualizar",  datos )
          .catch((error) => {
            if (error.response.status == 401) {
              store.commit("setUserData", { id: 0, userName: "", isAutenticated: false });
              router.push("/pages/login");
            }
          });
      }
}


export default new CategoriaServices();
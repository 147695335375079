<template>
  <div>
    <section :class="['ps-section--banner']">
      <div class="ps-banner" :style="{ backgroundColor: banner.bgColor }" v-if="banner">
        <img v-if="banner.imgbg" :class="['ps-banner__imagebg', banner.imgbg]" :src="banner.img" alt="alt" />
        <div :class="[banner.noFull ? 'container-no-round' : 'container container-initial']">
          <div :class="['ps-banner__block', reverse ? 'row-reverse' : '']">
            <div class="ps-banner__content">
              <p class="ps-banner__baged" v-if="banner.baged">
                <strong>{{ banner.baged }}</strong>
              </p>
              <h2>
                <span :class="['ps-banner__title', banner.textColor]"> {{ banner.title }}</span>
              </h2>
              <div :class="['ps-banner__desc', banner.textColor]" v-html="banner.desc"></div>
              <div v-if="banner.price.ins || banner.price.del" class="ps-banner__price">
                <span>{{ banner.price.ins }}</span>
                <del>{{ banner.price.del }}</del>
              </div>
              <div v-if="banner.button" class="ps-banner__btn-group">
                <div
                  :class="['ps-banner__btn', banner.buttonColor && banner.buttonColor]"
                  v-for="(item, index) in banner.button"
                  :key="index"
                >
                  <img :src="item.icon" alt="alt" :style="banner.filter && banner.filter" />{{ item.text }}
                </div>
              </div>
              <a
                :class="['ps-banner__shop', banner.shop, banner.cart]"
                :href="banner.linkShop"
                v-if="banner.shop || banner.cart"
                >{{ banner.textShop ? banner.textShop : banner.shop ? 'Comprar ahora' : 'Agregar al carrito' }}</a
              >
              <div
                :class="['ps-banner__persen', banner.percent.extraClass]"
                v-if="banner.percent && !banner.percent.thumbnail"
                v-html="banner.percent.text"
              ></div>
            </div>
            <div :class="['ps-banner__thumnail', { 'ps-banner__fluid': !banner.round }]">
              <img v-if="banner.round" class="ps-banner__round" :src="banner.round" alt="alt" />
              <img v-if="!banner.imgbg && banner.img" class="ps-banner__image" :src="banner.img" alt="alt" />
              <div
                :class="['ps-banner__persen', banner.percent.extraClass]"
                v-if="banner.percent && banner.percent.thumbnail"
              >
                {{ banner.percent.text }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  props: {
    banner: {
      type: Object,
      default: () => {},
    },
    reverse: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss">
$color-1st: rgba(16, 49, 120, 1);
$color-1st-lighter: lighten($color-1st, 20%);
$color-1st-light: lighten($color-1st, 10%);
$color-1st-dark: darken($color-1st, 10%);
$color-1st-darker: darken($color-1st, 20%);

$color-2nd: rgba(255, 200, 0, 1);
$color-2nd-lighter: lighten($color-2nd, 20%);
$color-2nd-light: lighten($color-2nd, 10%);
$color-2nd-dark: darken($color-2nd, 10%);
$color-2nd-darker: darken($color-2nd, 20%);

$color-success: #12a05c;

$color-success-lighter: lighten($color-success, 20%);
$color-success-light: lighten($color-success, 10%);
$color-success-dark: darken($color-success, 10%);
$color-success-darker: darken($color-success, 20%);

$color-info: #84fff7;

$color-info-lighter: lighten($color-info, 20%);
$color-info-light: lighten($color-info, 10%);
$color-info-dark: darken($color-info, 10%);
$color-info-darker: darken($color-info, 20%);

$color-warning: #fd8d27;
$color-warning-bg: #fdf6ed;
$color-warning-border: #ffebd3;

$color-warning-lighter: lighten($color-warning, 20%);
$color-warning-light: lighten($color-warning, 10%);
$color-warning-dark: darken($color-warning, 10%);
$color-warning-darker: darken($color-warning, 20%);

$color-danger: rgba(240, 0, 0, 1);

$color-danger-lighter: lighten($color-danger, 20%);
$color-danger-light: lighten($color-danger, 10%);
$color-danger-dark: darken($color-danger, 10%);
$color-danger-darker: darken($color-danger, 20%);

$color-border: rgba(240, 242, 245, 1);
$color-border1: rgba(217, 222, 232, 1);
$color-border2: rgba(40, 70, 134, 1);
$color-border3: #4b4b4b;
$color-border4: #d9d9d9;

$color-border-lighter: lighten($color-border, 20%);
$color-border-light: lighten($color-border, 10%);
$color-border-dark: darken($color-border, 10%);
$color-border-darker: darken($color-border, 20%);

$color-text: rgba(155, 171, 205, 1);

$color-text-lighter: lighten($color-text, 20%);
$color-text-light: lighten($color-text, 10%);
$color-text-dark: darken($color-text, 10%);
$color-text-darker: darken($color-text, 20%);

$color-contrast: rgba(91, 108, 143, 1);

$color-contrast-lower: darken($color-contrast, 5%);
$color-contrast-low: darken($color-contrast, 17%);
$color-contrast-medium: darken($color-contrast, 52%);
$color-contrast-high: darken($color-contrast, 80%);
$color-contrast-high: darken($color-contrast, 88%);

$color-heading: #222;
$color-dark: #333;
$color-dark1: #182947;
$color-gray: #9babcd;
$color-gray1: rgba(16, 49, 120, 0.1);
$color-gray2: #f4f5f7;
$color-gray4: #ededed;
$color-yellow: #ffc800;
$color-bg: rgba(247, 248, 250, 1);
$color-pink: #752a2b;
$color-green: #00a198;
$color-green1: #00605a;
$color-gray3: #f5f5f5;
$color-primary: #060572;

$color-white: #ffffff;
$color-secondary: #6c757d;

.ps-banner {
  min-height: 536px;
  position: relative;

  .ps-banner__content {
    padding: 40px 15px;
    text-align: center;
    height: 336px;
  }
  .ps-banner__title {
    font-weight: 600;
    font-size: 35px;
    color: $color-1st;
    line-height: 42px;
    margin-bottom: 10px;
  }

  .ps-banner__text {
    font-weight: 600;
    font-size: 41px;
    color: $color-warning;
    margin-bottom: 10px;
    line-height: 39px;
  }

  .ps-banner__desc {
    font-size: 20px;
    color: $color-1st;
    margin-bottom: 15px;
    line-height: 29px;
  }

  .ps-banner__price {
    position: relative;
    span {
      font-size: 40px;
      color: $color-warning;
      font-weight: 600;
    }
    del {
      font-size: 20px;
      color: $color-contrast;
      display: inline-block;
      position: relative;
      top: -12px;
      margin-left: 10px;
    }
  }

  .ps-banner__shop {
    font-size: 19px;
    font-weight: 500;
    padding: 5px 25px;
    border-radius: 40px;
    display: inline-block;
    margin-top: 15px;
    border: 1px solid $color-1st;

    &.bg-warning:hover {
      color: $color-warning !important;
      background-color: white !important;
    }

    &.bg-yellow:hover {
      color: $color-yellow !important;
      background-color: white !important;
    }

    &.bg-white:hover {
      color: white;
      border-color: $color-1st;
      background-color: $color-1st !important;
    }

    &.bg-primary:hover {
      background-color: white !important;
      color: $color-1st;
    }

    &.bg-success:hover {
      background-color: white !important;
      color: $color-success;
    }

    &.bg-pink:hover {
      background-color: white !important;
      color: $color-pink;
    }
  }

  .ps-banner__btn-group {
    margin: auto;
  }

  .ps-banner__btn {
    font-size: 12px;
    padding-right: 30px;
    color: $color-1st;

    &:last-child {
      padding-right: 0;
    }

    img {
      width: 21px;
      display: inline-block;
      margin-right: 10px;
    }

    &.font-bold {
      font-weight: 500;
    }
  }

  .ps-banner__persen {
    position: absolute;
    width: 66px;
    height: 66px;
    border-radius: 50%;
    z-index: 10;
    font-size: 22px;
    line-height: 22px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 25%;
    right: 15%;
    flex-direction: column;
    small {
      font-size: 14px;
      line-height: 14px;
      font-weight: bold;
    }
  }

  .ps-banner__thumnail {
    position: relative;
    height: 100%;
    width: 100%;
    .ps-banner__round {
      position: absolute;
      right: 5%;
      top: -90px;
      width: 90%;
    }
    .ps-banner__image {
      width: 100%;
      height: 200px;
    }
  }

  .bg-warning {
    color: white;
    background-color: $color-warning !important;
    border-color: $color-warning;
  }

  .bg-yellow {
    color: $color-1st;
    background-color: $color-yellow !important;
    border-color: $color-yellow;
  }

  .bg-white {
    color: $color-1st;
    background-color: white;
    border-color: white;
  }

  .bg-primary {
    background-color: $color-1st !important;
    color: white;
    border-color: $color-1st;
  }

  .bg-success {
    background-color: $color-success !important;
    color: white;
    border-color: $color-success;
  }

  .bg-pink {
    background-color: $color-pink !important;
    color: white;
    border-color: $color-pink;
  }

  .text-warning {
    color: $color-warning !important;
  }
  .text-secondary {
    color: $color-secondary !important;
  }
  .text-white {
    color: $color-white !important;
  }

  .text-success {
    color: $color-success !important;
  }
  .text-primary {
    color: $color-primary !important;
  }

  .text-danger {
    color: $color-danger !important;
  }
  .text-info {
    color: $color-info !important;
  }
  .text-yellow {
    color: $color-yellow !important;
  }

  .ps-logo {
    margin-bottom: 15px;
    img {
      height: 42px;
    }
  }

  @media (min-width: 768px) {
    height: 320px;
    min-height: 320px !important;
    .container,
    .container-no-round {
      height: 100%;
    }

    .ps-banner__block {
      display: flex;
      height: 100%;
      position: relative;
    }

    .ps-banner__content {
      padding: 8% 0 0;
      width: 48%;
      height: 100%;
      flex: 0 0 48%;
      text-align: left;
    }

    .ps-banner__thumnail {
      flex: 0 0 62%;
      .ps-banner__image {
        position: absolute;
        bottom: 0;
        right: -5%;
        height: 100%;
        object-fit: cover;
      }

      .ps-banner__round {
        z-index: 10;
        object-fit: cover;
        height: 190%;
        right: auto;
        left: -20%;
        width: auto;
        top: -200px;
        max-width: max-content;
      }
    }

    .ps-banner__btn-group {
      margin: 0;
      display: flex;
    }
  }

  @media (min-width: 1024px) {
    height: 400px;
  }

  @media (min-width: 1280px) {
    height: 600px;

    .ps-banner__title {
      font-size: 70px;
      line-height: 84px;
    }

    .ps-banner__desc {
      font-size: 24px;
    }

    .ps-banner__price {
      span {
        font-size: 70px;
      }
      del {
        font-size: 30px;
        top: -25px;
      }
    }

    .ps-banner__shop {
      font-size: 21px;
      padding: 8px 50px;
    }

    .ps-banner__btn {
      font-size: 16px;
    }

    .ps-banner__persen {
      width: 133px;
      height: 133px;
      font-size: 45px;
      line-height: 45px;
      right: 3%;
      small {
        font-size: 27px;
        line-height: 27px;
      }

      &.ps-center {
        right: 34%;
        top: 17%;
      }

      &.ps-top {
        top: 28%;
      }
    }
    .ps-banner__text {
      line-height: 80px;
      font-size: 70px;
    }

    .ps-logo {
      img {
        height: 54px;
      }
    }
  }
}

.ps-section--banner {
  min-height: 500px;
  position: relative;

  .ps-section__overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;
    animation: cssAnimation 0s ease-in 1s forwards;
    animation-fill-mode: forwards;
  }

  .ps-section__loading {
    border: 5px solid #f3f3f3;
    border-radius: 50%;
    border-top: 5px solid $color-1st;
    width: 30px;
    height: 30px;
    animation: spin 0.5s linear infinite;
  }

  @keyframes cssAnimation {
    to {
      width: 0;
      height: 0;
      overflow: hidden;
      z-index: -1;
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .ps-swiper__nav {
    display: none;
  }

  @media (min-width: 768px) {
    min-height: 320px;
  }

  @media (min-width: 1280px) {
    min-height: 500px;

    .ps-swiper__nav {
      display: block;
    }
  }
}

.ps-banner--round {
  .ps-banner {
    background-color: #f0f2f5;
    border-radius: 4px;
    overflow: hidden;
  }

  .ps-banner__content {
    text-align: left;
  }

  .ps-banner__title {
    font-size: 34px;
    margin-bottom: 35px;
  }

  .ps-banner__btn-group {
    display: block;
  }

  .ps-banner__btn {
    display: block;
    margin-bottom: 15px;
    color: $color-contrast;
    font-weight: 600;
    font-size: 16px;
  }

  .ps-banner__thumnail {
    margin-top: 70px;
    .ps-banner__image {
      height: 345px;
    }

    .ps-banner__round {
      width: auto;
      height: 400%;
      object-fit: cover;
      top: auto;
      bottom: -500px;
    }
  }

  @media (min-width: 768px) {
    .ps-banner {
      height: 455px;
    }
    .ps-banner__content {
      padding: 50px 0 30px 5%;
    }

    .ps-banner__btn-group {
      display: table;
      margin-bottom: 20px;
    }

    .ps-banner__btn {
      display: table-cell;
    }

    .ps-banner__thumnail {
      margin-top: 0;
      display: flex;
      align-items: flex-end;
      justify-content: center;

      .ps-banner__image {
        height: 90%;
        width: auto;
        object-fit: cover;
        position: initial;
      }

      .ps-banner__round {
        height: 300%;
        left: -38%;
      }
    }
  }

  @media (min-width: 1280px) {
    .ps-banner__title {
      font-size: 50px;
      line-height: 60px;
    }
  }
}

.ps-banner--container {
  margin-left: -15px;
  margin-right: -15px;
  overflow: hidden;
  border-radius: 4px;

  .ps-banner__fluid {
    position: initial;
    .ps-banner__image {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      object-fit: cover;
    }
  }

  .ps-banner__content {
    z-index: 20;
    position: relative;
    height: 280px;
    padding: 30px 15px 0;
  }

  .ps-banner {
    min-height: 510px;
  }

  .ps-banner__persen {
    right: 25%;
    bottom: -35%;
    &.ps-left {
      left: 10%;
      right: auto;
    }
  }

  .ps-swiper {
    .ps-swiper__nav {
      .swiper-button-prev {
        margin-left: 0;
      }

      .swiper-button-next {
        margin-right: 0;
      }
    }
  }

  @media (min-width: 768px) {
    margin: 0;
    .ps-banner__content {
      padding: 5% 0 0 4%;
      position: initial;
    }

    .ps-banner__persen {
      bottom: auto;
      top: 25%;
      right: 15%;

      &.ps-left {
        left: 40%;
        top: 40%;
      }
    }
  }

  @media (min-width: 1280px) {
    .ps-banner__content {
      padding: 5% 0 0 6%;
    }

    .ps-banner {
      min-height: 450px;
      height: 450px;

      .ps-banner__title {
        font-size: 50px;
        line-height: 1.2;
      }

      .ps-banner__desc {
        font-size: 22px;
      }

      .ps-banner__btn {
        font-size: 18px;
      }

      .ps-banner__shop {
        font-size: 18px;
      }

      .ps-banner__price {
        span {
          font-size: 50px;
          line-height: 1.2;
        }
        del {
          font-size: 25px;
        }
      }
    }
  }
}

.ps-banner--home7 {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding-top: 150px;
  height: 100vh;
  overflow: hidden;
  position: relative;
  min-height: 620px;

  .ps-banner__round {
    display: none;
    position: absolute;
    top: 0;
    left: 20%;
    width: 100%;
    height: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .ps-banner {
    height: 100%;

    .ps-banner__btn {
      color: $color-warning !important;

      img {
        filter: invert(60%) sepia(75%) saturate(1000%) hue-rotate(360deg) brightness(100%) contrast(100%);
      }
    }

    .ps-banner__content {
      height: auto;
    }

    .ps-banner__persen {
      position: initial;
      margin: auto;
      width: 120px;
      height: 120px;
      line-height: 120px;
      font-size: 36px;
    }
  }

  @media (min-width: 768px) {
    .ps-banner__round {
      display: block;
    }
    .ps-banner {
      padding-top: 120px;

      .ps-banner__content {
        padding-top: 50px !important;
      }

      .ps-banner__persen {
        width: 134px;
        height: 134px;
        line-height: 134px;
        font-size: 45px;
        position: absolute !important;
        left: 50%;
        top: 50%;
        transform: translate(-100%, -170%);
      }

      .ps-banner__thumnail {
        height: auto;
      }

      .ps-banner__btn-group {
        text-align: left !important;
      }
    }
  }

  @media (min-width: 992px) {
    .ps-banner {
      padding-top: 0;

      .ps-banner__thumnail {
        flex: 0 0 50%;
        position: relative;
      }

      .ps-banner__content {
        text-align: left;
        width: 50%;
        flex: 0 0 50%;
        padding-top: 0 !important;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      .ps-banner__shop {
        width: fit-content;
      }

      .ps-banner__baged {
        text-align: left;
      }

      .ps-banner__persen {
        transform: translate(-60%, -160%);
      }

      .ps-banner__btn-group {
        margin-bottom: 20px;
      }

      .ps-banner__btn {
        display: table-cell !important;
      }

      .ps-banner__title {
        font-size: 45px;
        line-height: 50px;
      }

      .ps-banner__desc {
        font-size: 22px;
        line-height: 26px;
      }
    }
    .ps-banner__round {
      top: 10%;
      left: 18%;
    }
  }

  @media (min-width: 1400px) {
    .ps-banner {
      .ps-banner__persen {
        position: absolute !important;
        left: 50%;
        top: 50%;
        transform: translate(-100%, -160%);
      }
    }
  }
}

.ps-banner--full {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: auto;
  padding-top: 130px;
  overflow: hidden;
  position: relative;

  .ps-banner {
    .ps-banner__content {
      padding-top: 0;
    }

    .ps-banner__title {
      font-size: 36px;
    }

    .ps-banner__title,
    .ps-banner__desc,
    .ps-banner__btn {
      margin-bottom: 15px;
    }

    .ps-banner {
      min-height: 456px;
    }

    .ps-banner__desc {
      font-size: 19px;
    }

    .ps-banner__btn {
      font-size: 16px;
      font-weight: 500;
    }

    .ps-banner__shop {
      padding: 9px 23px;
      font-size: 16px;
      margin-top: 20px;
    }
  }

  .ps-banner__group {
    display: flex;
  }

  .ps-banner__item {
    text-align: center;
    flex-grow: 1;
    padding: 0 5px;
    position: relative;

    p {
      color: white;
      margin-bottom: 0;
      font-size: 14px;
      margin-top: 10px;
    }

    img {
      width: 31px;
    }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 100%;
      height: 50%;
      width: 1px;
      background-color: $color-border;
      opacity: 0.5;
    }

    &:last-child {
      &::after {
        display: none;
      }
    }
  }

  @media (min-width: 768px) {
    height: 90vh;
    min-height: 620px;
    padding-top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .ps-banner__item {
      p {
        font-size: 15px;
      }

      img {
        width: 38px;
      }
    }

    .ps-banner {
      height: auto;
      position: initial;
      margin-top: -100px;

      .ps-banner__title {
        font-size: 39px;
      }

      .ps-banner__content {
        flex: 0 0 50%;
        width: 50%;
      }

      .ps-banner__block {
        height: auto;
      }
    }

    .ps-banner__group {
      position: absolute;
      width: 100%;
      left: 0;
      bottom: 40px;
    }
  }

  @media (min-width: 1280px) {
    height: calc(100vh - 65px);

    .ps-banner__item {
      p {
        font-size: 20px;
      }

      img {
        width: 48px;
      }
    }

    .ps-banner {
      .ps-banner__title {
        font-size: 70px;
      }

      .ps-banner__desc {
        font-size: 24px;
        margin-bottom: 25px;
      }

      .ps-banner__btn {
        font-size: 18px;
      }

      .ps-banner__shop {
        padding: 10px 50px;
        font-size: 20px;
        margin-top: 30px;
      }

      .ps-banner {
        height: 53vh;
      }
    }
  }
}

.ps-banner--landing {
  overflow: hidden;
  .ps-banner__round {
    display: none;
    bottom: 0;
    position: absolute;
    left: 35%;
  }

  .ps-banner__thumnail,
  .ps-banner__block {
    position: initial;
  }

  .ps-logo {
    margin-bottom: 40px !important;
  }

  .ps-banner {
    .ps-banner__title {
      margin-bottom: 40px;
    }

    .ps-banner__btn-group {
      margin-bottom: 30px;
    }

    .ps-banner__btn {
      display: block;
      padding-right: 0;
      margin-bottom: 10px;
    }

    .ps-banner__thumnail {
      overflow: hidden;

      .ps-banner__image {
        object-fit: cover;
        height: auto;
      }
    }

    .ps-banner__shop {
      margin-right: 10px;
      font-size: 14px;
      &:last-child {
        margin-right: 0;
      }
    }

    .ps-banner__content {
      height: auto;
      padding-bottom: 0;
    }
  }

  .ps-banner__arrow {
    padding-top: 50px;

    img {
      height: 55px;
    }
  }

  @media (min-width: 768px) {
    .ps-banner__round {
      display: block;
    }

    .ps-banner {
      height: auto;
      .ps-banner__btn {
        display: table-cell;
        padding-right: 20px;
        margin-bottom: 0;
      }

      .ps-banner__thumnail {
        flex: 0 0 40%;
        width: 40%;
        overflow: initial;
        .ps-banner__image {
          height: 100%;
          object-fit: cover;
          width: auto;
          top: 0;
          right: -50%;
        }
      }

      .ps-banner__shop {
        font-size: 16px;
      }

      .ps-banner__content {
        padding: 50px 0 100px;
        flex: 0 0 60%;
        width: 60%;
        height: 100%;
      }
    }
  }

  @media (min-width: 992px) {
    .ps-banner {
      .ps-banner__content,
      .ps-banner__thumnail {
        flex: 0 0 50%;
        width: 50%;
      }

      .ps-banner__thumnail {
        .ps-banner__image {
          right: -15%;
        }
      }
    }
  }

  @media (min-width: 1280px) {
    .ps-banner {
      .ps-banner__btn {
        padding-right: 30px;
      }

      .ps-banner__content,
      .ps-banner__thumnail {
        flex: 0 0 50%;
        width: 50%;
      }

      .ps-banner__thumnail {
        .ps-banner__image {
          right: -15%;
          top: -3%;
        }
      }
      .ps-banner__title {
        font-size: 60px;
        line-height: 74px;
      }

      .ps-banner__shop {
        font-size: 21px;
      }
    }
  }

  @media (min-width: 1336px) {
    .ps-banner {
      .ps-banner__thumnail {
        .ps-banner__image {
          right: -7%;
          top: -3%;
        }
      }
    }
  }

  @media (min-width: 1680px) {
    .ps-banner {
      .ps-banner__thumnail {
        .ps-banner__image {
          height: 120%;
          top: -10%;
          right: -6%;
        }
      }
    }
  }
}

.color-warning {
  color: $color-warning;
}

.ps-page {
  position: relative;
  .container {
    padding: 0 15px;
  }
}

.ps-page--sticky {
  padding-top: 66px;
  hr {
    margin: 0;
  }

  @media (min-width: 768px) {
    padding-top: 100px;
  }
}
.ps-page--product2 {
  .ps-product--detail {
    .ps-product__info {
      margin-bottom: 0;
    }
  }

  @media (min-width: 768px) {
    .ps-product--detail {
      .ps-product__info {
        margin-bottom: 80px;
      }
    }
  }
}

.ps-page--product3 {
  .ps-delivery {
    margin: 30px 0 80px;
  }
}

.ps-page--product4 {
  .ps-section--latest {
    margin-bottom: 50px;
  }

  .ps-product--detail {
    .ps-document {
      padding: 0;
      border: none;
    }
  }
}

.ps-page--product5 {
  .ps-product--detail {
    background-color: $color-border;
    padding-top: 40px;

    .ps-product__info {
      background-color: white;
      border-radius: 4px;
      margin-bottom: 40px;
      padding: 30px 20px;
    }

    .ps-product__quantity {
      .number-input {
        margin-bottom: 15px;

        button {
          padding: 14px;
        }
      }
    }

    .ps-product__group {
      display: flex;
      h6 {
        margin-right: 15px;
      }
      a {
        border: 1px solid $color-border;
      }
    }
  }

  .ps-page__content {
    background-color: white;
    padding-top: 80px;
  }
  .ps-delivery {
    margin: 80px 0;
  }

  .ps-product--gallery {
    .swiper-wrapper {
      margin-left: calc(-50% + 72px);
    }
  }

  @media (min-width: 768px) {
    .ps-product--detail {
      .ps-product__info {
        padding: 30px;
      }

      .ps-product__quantity {
        .d-md-flex {
          flex-wrap: wrap;
        }

        .number-input {
          flex: 0 0 100%;
        }

        .ps-btn {
          flex: 0 0 100%;
          margin-left: 0;
        }
      }
    }
  }

  @media (min-width: 960px) {
    .ps-product--detail {
      .ps-product__quantity {
        .number-input {
          flex: 0 0 40%;
          max-width: 40%;
          margin-right: 10px;
          margin-bottom: 0;
        }

        .ps-btn {
          flex: 0 0 50%;
        }
      }
    }
  }

  @media (min-width: 1280px) {
    .ps-product--detail {
      .ps-product__info {
        padding: 40px;
      }
    }
  }
}

.ps-page--product6 {
  .ps-footer--banner {
    display: none;
  }

  .ps-breadcrumb__item {
    a {
      color: $color-dark;
    }
  }

  .ps-section--latest {
    margin-bottom: 80px;
    .ps-section__title {
      color: $color-dark;
    }
  }

  .ps-swiper {
    .swiper-pagination {
      .swiper-pagination-bullet-active {
        background-color: $color-dark;
      }
    }

    .ps-swiper__nav {
      div {
        &:hover {
          background-color: $color-dark !important;
        }
      }
    }
  }

  .ps-product--standard {
    .ps-product__title,
    .ps-product__del {
      color: $color-dark;
    }

    .ps-product__footer {
      color: $color-dark;
      span {
        color: $color-dark;
      }
    }

    .ps-product__percent {
      background-color: $color-dark;
    }

    .ps-product__price {
      color: $color-dark;
      &.sale {
        color: $color-warning;
      }
    }
  }

  .ps-product--detail {
    .ps-product__branch,
    .ps-product__title,
    .ps-product__rating .ps-product__review,
    .ps-product__list li,
    h6,
    .ps-product__link {
      color: $color-dark;
    }

    .ps-product__price {
      color: $color-dark;

      &.sale {
        color: $color-warning;
      }
    }

    .ps-product__quantity {
      .number-input {
        button i,
        input[type='number'] {
          color: $color-dark;
        }
      }
    }

    .ps-product__size a {
      color: $color-dark;
      &.active {
        border-color: $color-dark !important;
      }
    }

    .ps-tab-list {
      li {
        a {
          color: $color-dark;

          &.active {
            border-color: $color-dark;
          }
        }
      }
    }

    .v-tabs {
      .v-tab,
      .v-tab:not(.v-tab--active),
      .primary--text {
        color: $color-dark !important;
      }
    }
  }

  .ps-form--review {
    .ps-form__title,
    .ps-form__desc,
    .ps-form__label {
      color: $color-dark;
    }
  }

  .ps-review--product {
    .ps-review__name,
    .ps-review__date,
    .ps-review__desc p {
      color: $color-dark;
    }
  }

  .ps-table {
    th,
    td {
      color: $color-dark;
    }
  }

  .ps-product__content {
    .ps-desc {
      color: $color-dark;
    }
  }

  @media (min-width: 768px) {
    .ps-product--detail {
      .ps-product__feature-group {
        display: flex;

        .ps-product__group {
          margin-right: 30px;

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }
}

.ps-page--product-variable {
  .ps-section--latest {
    background-position: center;
    padding: 70px 0;
    margin-bottom: 30px;

    .owl-carousel {
      background-color: white;
    }
  }
}

.ps-categogy--promo {
  .ps-section--category-horizontal {
    padding: 30px 0 50px;
    .ps-section__title,
    .ps-category__all {
      display: block;
    }
    &::after {
      display: none;
    }
  }

  .ps-section--blog {
    padding: 30px 0 50px;
  }

  .ps-branch {
    margin: 30px 0 50px;
    .ps-branch__title {
      display: block;
    }
  }

  .ps-promo {
    padding-bottom: 50px;
    .ps-promo__sale {
      position: absolute;
      top: 50%;
      transform: translate(0, -80%);
      left: 100%;
      font-weight: 600;
      color: $color-1st;
      width: 90px;
      height: 90px;
      border-radius: 50%;
      background-color: $color-yellow;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      font-size: 30px;
    }

    .ps-promo__price {
      font-weight: 700;
      color: $color-warning;
    }

    .ps-promo__name {
      margin-bottom: 15px;
      font-size: 30px;
    }

    .ps-promo__meta {
      margin-bottom: 20px;
    }

    .ps-promo__price {
      font-size: 30px;
    }

    .ps-promo__btn {
      background-color: $color-warning;
      border-color: $color-warning;
      font-size: 16px;
      padding: 7px 40px;
    }

    .ps-promo__del {
      font-size: 18px;
    }

    .ps-promo__banner {
      width: auto;
    }
  }

  .ps-section--deals {
    padding: 30px 0 50px;
  }

  .ps-section--featured {
    padding: 30px 0 50px;
  }

  .ps-section--newsletter {
    padding: 30px 0 80px;
  }

  @media (min-width: 768px) {
    .ps-promo {
      .ps-promo__sale {
        width: 100px;
        height: 100px;
        font-size: 35px;
      }

      .ps-promo__name {
        font-size: 40px;
      }

      .ps-promo__del {
        font-size: 21px;
      }

      .ps-promo__price {
        font-size: 40px;
      }
    }
  }

  @media (min-width: 1280px) {
    .ps-promo {
      .ps-promo__sale {
        font-size: 45px;
        width: 133px;
        height: 133px;
      }
      .ps-promo__price,
      .ps-promo__name {
        font-size: 50px;
      }

      .ps-promo__del {
        font-size: 25px;
      }

      .ps-promo__btn {
        font-size: 18px;
        padding: 9px 40px;
      }
    }
  }
}

.ps-about--info {
  .ps-about__title {
    font-size: 34px;
    line-height: 40px;
    margin-bottom: 20px;
    text-align: center;
    color: $color-1st;
    font-weight: 600;
  }

  .ps-about__subtitle {
    font-size: 16px;
    line-height: 26px;
    margin-bottom: 50px;
    color: $color-contrast;
    text-align: center;
    font-weight: 500;
  }

  .ps-about__extent {
    .col-12 {
      border-top: 1px solid $color-border;

      &:first-child {
        border-top: none;

        .ps-block--about {
          padding-top: 0;
        }
      }
    }
  }

  @media (min-width: 768px) {
    .ps-about__extent {
      padding-top: 30px;
      .col-12 {
        border-top: none;
        border-right: 1px solid $color-border;

        .ps-block--about {
          padding-top: 0;
          padding-bottom: 0;
        }

        &:last-child {
          border-right: none;
        }
      }
    }
  }

  @media (min-width: 1280px) {
    .ps-about__title {
      font-size: 50px;
      line-height: 60px;
    }

    .ps-about__subtitle {
      font-size: 24px;
      line-height: 30px;
    }
  }
}

.ps-block--about {
  text-align: center;
  padding: 30px 10px;

  .ps-block__icon {
    margin-bottom: 20px;
    img {
      width: 48px;
      transition: 350ms ease;
    }

    &:hover {
      img {
        filter: grayscale(1);
      }
    }
  }

  .ps-block__title {
    font-size: 19px;
    line-height: 26px;
    color: $color-1st;
    font-weight: 600;
  }

  .ps-block__subtitle {
    font-size: 16px;
    color: $color-contrast;
  }

  @media (min-width: 1280px) {
    .ps-block__title {
      font-size: 21px;
      line-height: 24px;
    }
  }
}

.ps-about--video {
  border-top: 1px solid $color-border;
  .ps-banner {
    height: auto;

    .ps-banner__title {
      margin-bottom: 20px;
    }
    .ps-banner__desc {
      margin-bottom: 30px;
    }
    .ps-banner__btn-group {
      margin-bottom: 30px;
      display: block;
    }
    .ps-banner__content {
      padding-top: 0;
      padding-bottom: 0;
    }
    .ps-banner__thumnail {
      padding-top: 30px;
      text-align: center;
      position: relative;
    }

    .ps-banner__btn {
      display: block;
      color: $color-contrast;

      img {
        filter: invert(40%) sepia(50%) saturate(366%) hue-rotate(182deg) brightness(80%) contrast(89%);
      }
    }

    .ps-banner__image {
      height: auto;
      position: initial;
    }
  }

  #ps-video-gallery {
    width: 60px;
    height: 60px;
    margin-left: -30px;
    margin-top: -10px;
    position: absolute;
    left: 50%;
    top: 50%;
  }
  .ps-banner__video {
    background-color: white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;

    i {
      color: $color-1st;
      font-size: x-large;
      margin-left: 5px;
    }
  }

  @media (min-width: 768px) {
    .ps-banner {
      .ps-banner__block {
        display: block;
      }

      .ps-banner__content {
        width: 100%;
        text-align: center;
      }

      .ps-banner__image {
        max-width: 380px;
      }
    }
  }

  @media (min-width: 992px) {
    .ps-banner {
      .ps-banner__btn-group {
        display: table;
      }
      .ps-banner__btn {
        display: table-cell;
      }

      .ps-banner__block {
        display: flex;
        align-items: center;
      }

      .ps-banner__content {
        text-align: left;
        flex: 0 0 50%;
      }

      .ps-banner__thumnail {
        flex: 0 0 50%;
      }
      .ps-banner__image {
        max-width: 100%;
      }
    }
  }

  @media (min-width: 1280px) {
    .ps-banner {
      .ps-banner__title {
        font-size: 50px;
        line-height: 60px;
      }

      .ps-banner__btn {
        font-size: 16px;
      }

      .ps-banner__shop {
        font-size: 18px;
      }
    }

    .ps-banner__video {
      width: 100px;
      height: 100px;

      i {
        font-size: xx-large;
      }
    }

    #ps-video-gallery {
      margin-left: -50px;
      margin-top: -30px;
    }
  }
}

.ps-about {
  .ps-about__content {
    overflow: hidden;
  }

  .ps-about__title {
    font-size: 34px;
    line-height: 40px;
    margin-bottom: 20px;
    text-align: center;
    color: $color-1st;
  }
  .ps-about__banner {
    background-size: cover;
    background-position: 60% center;
    background-repeat: no-repeat;
    margin-bottom: 50px;
    padding: 100px 10px;

    .ps-banner {
      color: white;
      min-height: 300px;
    }

    .ps-banner__title {
      font-size: 30px;
      line-height: 35px;
      color: white;
      font-weight: 600;
      margin-bottom: 25px;
    }
    .ps-banner__desc {
      line-height: 32px;
      margin-bottom: 15px;
      font-size: 19px;
      color: white;
    }
    .ps-banner__shop {
      font-size: 16px;
      border: 1px solid $color-1st;
      background-color: $color-1st;
      color: white;
      font-weight: 500;
      border-radius: 40px;
      padding: 8px 15px;
      display: inline-block;
      min-width: 150px;
      text-align: center;

      &:hover {
        background-color: white;
        color: $color-1st;
      }
    }
  }

  .ps-banner--round {
    .container {
      padding: 0;
    }
    .ps-banner__btn {
      img {
        filter: invert(40%) sepia(50%) saturate(366%) hue-rotate(182deg) brightness(80%) contrast(89%);
      }
    }

    .ps-banner {
      background-color: transparent;
    }

    .ps-banner__thumnail {
      text-align: center;
      .ps-banner__image {
        width: auto;
      }

      .ps-banner__round {
        height: 400px;
        bottom: 0;
        width: 100%;
        right: 0;
      }
    }
  }

  .ps-about__project {
    padding: 30px 0 50px;
  }

  .ps-section--newsletter {
    padding: 30px 0 80px;
  }

  .ps-section--blog {
    padding: 30px 0 50px;
  }

  // .ps-section--reviews {
  //     margin: 30px 0 50px;
  //     padding: 60px 10px 100px;
  // }

  .ps-about--info {
    padding: 80px 0;
  }

  .ps-about--video {
    padding: 50px 0;
  }

  .ps-reviews--2 {
    padding: 80px 0;
    .ps-section__title {
      text-align: center;
    }
  }

  @media (min-width: 768px) {
    .ps-about__banner {
      background-position: right center;

      .ps-banner__desc {
        font-size: 22px;
      }

      .ps-banner__title {
        font-size: 50px;
        line-height: 55px;
      }

      .ps-banner {
        width: 80%;
        height: fit-content;
      }
    }

    .ps-banner--round {
      .container {
        padding: 0 15px;
      }

      .ps-banner {
        background-color: #f0f2f5;
      }

      .ps-banner__thumnail {
        .ps-banner__round {
          height: 150%;
          bottom: -70px;
          right: 2%;
          left: auto;
        }
      }
    }
  }

  @media (min-width: 992px) {
    .ps-about__banner {
      .ps-banner {
        width: 60%;
      }
    }

    .ps-banner--round {
      .ps-banner__thumnail {
        .ps-banner__round {
          height: 300%;
          bottom: -420px;
          right: -1%;
        }
      }
    }

    // .ps-section--reviews {
    //     padding-left: 20px;
    //     padding-right: 20px;
    // }
  }

  @media (min-width: 1280px) {
    .ps-about__title {
      font-size: 50px;
      line-height: 60px;
    }

    .ps-about__banner {
      .ps-banner {
        width: 45%;
      }
      .ps-banner__title {
        font-size: 50px;
        line-height: 60px;
      }

      .ps-banner__desc {
        font-size: 21px;
      }

      .ps-banner__shop {
        font-size: 18px;
      }
    }

    // .ps-section--reviews {
    //     padding-left: 5px;
    //     padding-right: 5px;
    // }
  }

  // @media (min-width: 1350px) {
  //     .ps-section--reviews {
  //         padding-left: 40px;
  //         padding-right: 40px;
  //     }
  // }
}

.ps-contact {
  padding-bottom: 30px;

  .ps-contact__content {
    padding: 20px 0 50px;
  }
  .ps-contact__title {
    font-size: 34px;
    line-height: 40px;
    color: $color-1st;
    margin-bottom: 20px;
    font-weight: 600;
  }
  .ps-contact__text {
    font-size: 16px;
    line-height: 26px;
    margin-bottom: 25px;
    color: $color-contrast;
    margin-bottom: 20px;
  }
  .ps-contact__fax {
    color: $color-1st;
    font-size: 22px;
    line-height: 26px;
    padding-top: 25px;
    border-top: 1px solid $color-border;
    font-weight: 600;
  }
  .ps-contact__work {
    color: $color-contrast;
    font-size: 16px;
    line-height: 26px;
    margin-bottom: 24px;
  }
  .ps-contact__email {
    padding-bottom: 30px;
    border-bottom: 1px solid $color-border;

    a {
      border-radius: 40px;
      color: $color-1st;
      padding: 8px 15px;
      font-size: 16px;
      line-height: 24px;
      font-weight: 600;
      min-width: 150px;
      display: inline-block;
      border: 1px solid $color-border;

      &:hover {
        color: white;
        background-color: $color-warning;
        border-color: $color-warning;
      }
    }
  }

  .ps-social {
    margin-bottom: 40px;
  }

  .ps-section--instagram {
    padding: 80px 0 50px;
  }

  @media (min-width: 960px) {
    .ps-contact__title {
      font-size: 45px;
      line-height: 50px;
    }

    .ps-contact__info {
      padding-right: 10px;
    }

    .ps-contact__map {
      padding-left: 20px;
    }
  }

  @media (min-width: 1280px) {
    .ps-contact__title {
      font-size: 50px;
      line-height: 60px;
    }

    .ps-contact__fax {
      font-size: 24px;
      line-height: 30px;
    }

    .ps-contact__email {
      a {
        font-size: 18px;
      }
    }
  }
}

.ps-wishlist {
  //@extend %list-reset;

  .ps-wishlist__title {
    margin-bottom: 30px;
    color: $color-1st;
    font-size: 26px;
    line-height: 30px;
  }

  .ps-breadcrumb {
    padding: 20px 0 !important;
  }

  .ps-wishlist__list {
    li {
      margin-bottom: 10px;
    }
  }

  .ps-wishlist__table {
    display: none;
  }

  .ps-wishlist__share {
    padding-top: 30px;
    margin-bottom: 100px;

    label {
      color: $color-1st;
      font-weight: 600;
      margin-right: 10px;
      font-size: 16px;
    }
  }

  .ps-social--color {
    display: inline-block;

    .ps-social__email,
    .ps-social__whatsapp {
      display: inline-block;
    }

    .ps-social__linkedin,
    .ps-social__reddit {
      display: none;
    }

    .ps-social__link {
      border-radius: 50%;
    }

    li {
      margin: 0 2px 8px;
    }
  }

  @media (min-width: 992px) {
    .ps-wishlist__list {
      display: none;
    }

    .ps-wishlist__table {
      display: block;
    }
  }

  @media (min-width: 1280px) {
    .ps-wishlist__title {
      font-size: 30px;
      line-height: 40px;
    }
  }
}

.ps-account {
  min-height: 80vh;

  .ps-form--review {
    margin: 20px 0 80px;
  }

  .ps-form__group {
    margin-bottom: 30px;
  }

  .v-input__icon {
    .v-icon {
      min-width: 30px;
      padding-right: 10px;
      background-color: $color-border;
      display: inline-flex;
      align-items: center;
      border-top-right-radius: 40px;
      border-bottom-right-radius: 40px;
      font-size: 14px;
    }
  }

  .ps-form__title {
    margin-bottom: 30px;
  }

  .ps-account__link {
    display: inline-block;
    margin-top: 25px;
    color: $color-1st;
    text-decoration: underline;
  }

  .ps-form__label {
    font-weight: 400;
    font-size: 16px;
  }

  .ps-form__submit {
    padding-top: 30px;
  }

  .form-check {
    display: inline-block;
    margin-left: 30px;
    label {
      margin-top: 4px;
      padding-left: 25px;
      &::before {
        margin-right: 10px;
        content: '';
      }
    }
  }

  .ps-btn {
    font-weight: 500;
  }

  .ps-form__text {
    color: $color-1st;
    line-height: 20px;
    font-size: 14px;
    margin-top: 30px;
  }

  @media (min-width: 768px) {
    padding-top: 80px;
  }
}

.ps-forget-password {
  .ps-forget-password__content {
    padding-top: 20px;
    min-height: 500px;
  }

  .ps-forget-password__text {
    color: $color-1st;
    font-size: 16px;
    margin-bottom: 25px;
  }

  .ps-form__group {
    margin-bottom: 25px;
  }

  .ps-form__label {
    font-weight: 400;
    font-size: 16px;
  }

  .ps-btn {
    font-weight: 500;
  }

  @media (min-width: 960px) {
    .ps-form__group {
      width: 50%;
    }
  }
}

.ps-checkout {
  .ps-checkout__title {
    color: $color-1st;
    font-size: 34px;
    line-height: 40px;
  }

  .ps-checkout__text {
    color: $color-contrast;
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 5px;
    a {
      color: $color-1st;
      font-weight: 600;
    }
  }

  .ps-checkout__wapper {
    margin-bottom: 30px;
  }

  .ps-checkout__content {
    padding-top: 40px;
  }

  .ps-checkout__group {
    margin-bottom: 20px;
  }
  .ps-checkout__label {
    color: $color-1st;
    margin-bottom: 5px;
    font-size: 14px;
    display: block;
  }

  .ps-checkout__heading {
    color: $color-1st;
    font-size: 26px;
    line-height: 30px;
    margin-bottom: 20px;
  }

  .ps-input,
  .ps-textarea {
    border-radius: 40px;
    height: 44px;
    border: none;
    width: 100%;
    background-color: $color-border;
    padding: 5px 0;

    input {
      color: $color-contrast !important;
      font-size: 14px;

      &::placeholder {
        color: $color-contrast !important;
        font-size: 14px;
      }
    }
  }

  .ps-textarea {
    height: auto;
  }

  .ps-checkout__form {
    border: 1px solid $color-border;
    padding: 15px 20px 20px;
    border-radius: 4px;
    margin-bottom: 50px;
  }

  .ps-checkout__order {
    border: 3px solid $color-warning;
    border-radius: 4px;
    padding: 25px 20px 30px;
    margin-bottom: 50px;

    .form-check {
      label {
        color: $color-contrast;
      }
    }
  }

  .ps-checkout__row {
    display: flex;
    border-bottom: 1px solid $color-border;
    padding: 15px 0;
    justify-content: space-between;
    align-items: center;
  }

  .toogle-password {
    min-width: 30px;
    padding-right: 10px;
    background-color: $color-border;
    display: inline-flex;
    align-items: center;
    border-top-right-radius: 40px;
    border-bottom-right-radius: 40px;
    font-size: 14px;
  }

  .ps-label--danger {
    color: $color-danger;
  }

  .ps-hidden {
    display: none;
  }

  .form-check {
    label {
      margin-top: 4px;
      color: $color-1st;
      font-size: 14px;
      padding-left: 25px;
      &::before {
        content: '';
        margin-right: 10px;
      }

      span {
        font-weight: 600;
        color: $color-1st;
      }

      img {
        max-width: 100px;
        margin: 0 5px;
      }
    }

    input {
      &:focus {
        box-shadow: none;
      }
    }
  }

  .ps-title {
    color: $color-1st;
    font-weight: 600;
    font-size: 14px;
  }

  .ps-product__price {
    color: $color-1st;
    font-weight: 600;
    font-size: 16px;
    line-height: 26px;
    min-width: 100px;
    text-align: right;
  }

  .ps-product__name {
    color: $color-1st;
    font-size: 14px;

    span {
      font-weight: 600;
    }
  }

  .ps-checkout__payment {
    padding-top: 15px;
  }

  .ps-btn {
    padding: 10px 25px;
    text-transform: initial;
    font-weight: 500;
    height: 44px;
    font-size: 16px;
    margin-top: 15px;
  }

  .payment-method {
    margin-bottom: 15px;
    position: relative;
    .ps-note {
      background-color: $color-border;
      color: $color-contrast;
      border-radius: 4px;
      padding: 18px 25px;
      margin-top: 10px;
      position: relative;

      &::before {
        content: '';
        position: absolute;
        bottom: 100%;
        left: 10%;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 10px solid $color-border;
      }
    }
  }

  .paypal-method {
    margin-bottom: 15px;
    label {
      margin-top: 0;
    }
  }

  .ps-checkout__success {
    padding-bottom: 50px;
    .ps-checkout__item {
      border-right: 1px dashed #d3ced2;
      padding: 0 25px;

      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
        border-right: none;
      }
    }

    .ps-checkout__box {
      display: flex;
      align-items: center;
      margin-top: 20px;
    }

    .ps-checkout__content,
    .ps-table__th-empty {
      font-size: 14px;
      color: $color-1st;
      font-weight: 700;
    }

    .ps-checkout__label {
      font-size: 12px;
      color: $color-contrast;
    }

    .ps-checkout__subtitle {
      font-size: 30px;
      color: $color-1st;
      margin-bottom: 20px;
      margin-top: 40px;
    }

    .ps-table {
      td {
        font-size: 16px;
        color: $color-contrast;
        border-right: none;
        padding: 20px;
      }

      th {
        background-color: transparent;
      }
      tbody {
        border: 1px solid rgba(16, 49, 120, 0.1);
      }

      .ps-product__name {
        color: $color-1st;
        font-size: 16px;
      }
    }
  }

  @media (min-width: 767px) {
    .ps-checkout__form {
      padding: 15px 20px 20px;
    }
  }

  @media (min-width: 992px) {
    .ps-checkout__title {
      font-size: 45px;
      line-height: 50px;
    }
  }

  @media (min-width: 1280px) {
    .ps-checkout__title {
      font-size: 50px;
      line-height: 60px;
    }

    .ps-checkout__heading {
      font-size: 30px;
      line-height: 40px;
    }

    .ps-input {
      font-size: 16px;
      height: 46px;

      &:hover {
        font-size: 16px;
      }
    }

    .ps-checkout__form {
      padding: 25px 30px 30px;
      margin-bottom: 90px;
    }

    .ps-checkout__order {
      margin-bottom: 90px;
    }

    .ps-btn {
      height: 46px;
      font-size: 18px;
    }
  }
}

.scroll-top {
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  width: 40px;
  height: 40px;
  position: fixed;
  bottom: 60px;
  right: 20px;
  padding: 0;

  i {
    color: white;
    font-size: 22px;
    line-height: 40px;
    font-weight: 600;
  }

  &:focus {
    outline: none;
    box-shadow: none;
  }

  &:hover {
    background-color: $color-1st;
  }

  @media (min-width: 768px) {
    bottom: 20px;
  }
}

.ps-compare {
  .ps-compare__content {
    padding: 20px 0 40px;
  }
}

.ps-compare--product {
  .ps-compare__table {
    padding: 0;
  }

  .ps-compare__header {
    background-color: rgba(16, 49, 120, 1);
    padding: 14px 20px;
    h2 {
      color: white;
      text-transform: uppercase;
      font-size: 20px;
      margin-bottom: 0;
    }
  }

  .ps-table {
    //@extend %list-reset;
    tr {
    }

    th {
      color: $color-1st;
      font-weight: 600;
      width: 150px;
      font-size: 14px;
      padding: 20px;
      border-color: #f0f2f5;
    }

    td {
      padding: 20px;
      font-size: 14px;
      color: $color-contrast;
      min-width: 220px;
    }

    .ps-product__list {
      li {
        color: $color-contrast;
        padding: 8px 0;
        font-size: 14px;

        &:first-child {
          padding-top: 0;
        }

        &:last-child {
          padding-bottom: 0;
        }
      }
    }

    .ps-product__text {
      color: $color-contrast;
      font-size: 14px;
      margin-bottom: 0;
    }

    .ps-check-line {
      &::before {
        content: '\f00c';
        color: $color-success;
        font-size: 75%;
        display: inline-block;
        font-weight: 900;
        font: normal normal normal 14px/1 FontAwesome;
        margin: -3px 10px 0 0;
      }
    }

    .ps-product__price {
      color: $color-1st;
      font-weight: 600;
      font-size: 18px;

      &.sale {
        color: $color-warning;
      }
    }

    .ps-product__del {
      color: $color-text;
      margin-left: 5px;
      text-decoration: line-through;
      font-weight: 400;
      font-size: 15px;
      margin-left: 0;
      margin-right: 10px;
    }

    .ps-product__title {
      line-height: 20px;
      margin-bottom: 12px;
      color: $color-contrast;
      font-size: 16px;
    }

    .ps-product__meta {
      margin-bottom: 12px;
    }

    .ps-btn {
      text-transform: initial;
      font-size: 14px;
      font-weight: 500;
      padding: 4px 15px;
      width: auto;
      min-width: 145px;
      margin-bottom: 10px;
    }

    .ps-product__thumbnail {
      margin-bottom: 20px;
      img {
        width: 250px;
      }

      figure {
        text-align: center;
      }
    }

    .ps-product__remove {
      padding: 5px 0 10px;
      text-align: center;

      a {
        width: 34px;
        height: 34px;
        line-height: 34px;
      }
      i {
        font-size: 16px;
        line-height: 26px;
        color: $color-1st;
      }
    }
  }
}

.ps-section--sellers {
  padding-bottom: 80px;
}

.ps-promo {
  padding-bottom: 50px;
  &.ps-not-padding {
    margin-left: -15px;
    margin-right: -15px;

    .ps-promo__banner {
      border-radius: 0;
    }
  }
}

.ps-section--category {
  padding: 30px 0;
}

.ps-delivery {
  margin: 30px 0 50px;
}

.ps-section--latest {
  margin-bottom: 50px;
}

.ps-section--sellers {
  padding: 30px 0 50px;
}

.ps-section--deals {
  padding: 30px 0 50px;
}

.ps-section--featured {
  padding: 30px 0 50px;
}

.ps-section--reviews {
  margin: 30px 0 50px;
  padding: 60px 10px 100px;
}

.ps-section--instagram {
  padding: 30px 0 50px;
}

.ps-section--newsletter {
  padding: 30px 0 60px;
}

@media (min-width: 768px) {
  .ps-promo {
    padding-top: 30px;

    &.ps-not-padding {
      margin-left: 0;
      margin-right: 0;

      .ps-promo__banner {
        border-radius: 4px;
      }
    }
  }
}

@media (min-width: 992px) {
  .ps-section--reviews {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media (min-width: 1280px) {
  .ps-section--reviews {
    padding-left: 5px;
    padding-right: 5px;
  }
}

@media (min-width: 1350px) {
  .ps-section--reviews {
    padding-left: 40px;
    padding-right: 40px;
  }
}
</style>
